import { Close, Menu } from "@styled-icons/evaicons-solid/";
import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import styled from "styled-components";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em;
  position: fixed;
  top: 0;
  width: calc(100vw - 2em);
  align-items: center;
  z-index: 100000;
`;

const StyledMenu = styled(Menu)`
  color: white;
`;
const StyledClose = styled(Close)`
  color: white;
  margin-bottom: 0.5rem;
  align-self: flex-end;
  @media (min-width: 881px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  @media (min-width: 900px) {
    display: none;
  }
`;

const MobileMenuModal = styled.div`
  background-color: #06374d;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  padding: 1em;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100vw - 2em);
`;

const Link = styled.a`
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  margin: 0.8rem 0;
  font-family: "oliwettitypewriter";
`;

const DesktopMenu = styled.div`
  display: flex;
  gap: 2em;
  @media (max-width: 899px) {
    display: none;
  }
`;

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <HeaderContainer>
      <StaticImage
        src="../../images/logo.png"
        alt="N.O.R.P.P.A logo"
        width={50}
      />
      <MobileMenu>
        <StyledMenu height="3rem" onClick={() => setMenuOpen(true)} />
      </MobileMenu>
      <DesktopMenu>
        <Link href="/">N.O.R.P.P.A</Link>
        <Link href="/uskomuksetjatavat">Uskomukset ja tavat</Link>
        <Link href="/pyhattekstit">Pyhät tekstit</Link>
      </DesktopMenu>
      {menuOpen && (
        <MobileMenuModal>
          <StyledClose height="3rem" onClick={() => setMenuOpen(false)} />
          <Link href="/">N.O.R.P.P.A</Link>
          <Link href="/uskomuksetjatavat">Uskomukset ja tavat</Link>
          <Link href="/pyhattekstit">Pyhät tekstit</Link>
        </MobileMenuModal>
      )}
    </HeaderContainer>
  );
}
