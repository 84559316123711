import React from "react";
import { createGlobalStyle } from "styled-components";
import "../../styles/typography.css";
import Header from "../header";

const GlobalStyle = createGlobalStyle`
  body {
    background: #06374D;
    margin: 0;
    font-family: zillaslap-light;
    color: white;
    h1, h2 {
      font-family: oliwettitypewriter;
    }
    p{
    }
  }
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyle />
      <Header />
      {children}
    </>
  );
}
