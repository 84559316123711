import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";

const DarkSection = styled.div`
  background: #06374d;
  padding: 4rem 2rem;
  @media (min-width: 900px) {
    padding: 3rem 6rem;
  }
  @media (min-width: 1500px) {
    padding: 3rem 12rem;
  }
`;

const LightSection = styled.div`
  background: #115876;
  padding: 4rem 2rem;
  @media (min-width: 900px) {
    padding: 3rem 6rem;
  }
  @media (min-width: 1500px) {
    padding: 3rem 12rem;
  }
`;

const NorppaWrapper = styled.div`
  margin: 3rem 0;
`;

const ImageWrapper = styled.div`
  width: 60%;
  max-width: 25rem;
  margin: 0 auto;
`;

export default function UskomuksetPage(params) {
  return (
    <Layout>
      <title>Uskomukset ja tavat</title>
      <DarkSection>
        <h1>N.O.R.P.P.A jumalat</h1>

        <NorppaWrapper>
          <ImageWrapper>
            <StaticImage
              src="../images/pällervö.png"
              alt="Pällervö"
              layout="constrained"
            />
          </ImageWrapper>
          <h2>
            Pällervö – Alfajumala, stalkkauksen ja sivustakatsomisen jumalatar
          </h2>

          <p>
            Kaikkien norppien äiti, Cthulhun suora jälkeläinen ja Pullervon
            puoliso. Ensimmäisenä norppana joutunut kokemaan ja näkemään paljon,
            tästä johtuu taipumus kausittaisiin paranoidipsykooseihin. Rakastava
            ja huolehtiva äiti, vahva ja arvokas puoliso, temperamenttinen ja
            määrätietoinen rakastaja. Pällervö toimii oikeutetusti muiden
            norppien johtajana ja näin vaikuttavimpana ja pelottavimpana
            jumalana. Pällervö tietää aina missä olet ja mitä/ketä duunaat.
          </p>
          <p>Aura: Vainoharhainen aura, Kesä- ja joulukuu</p>
          <p>
            Uhraus: Päästä vainoharhaisimmista ajatuksistasi irti lahjoittamalla
            ne Pällervön auraan (jaa ne kuitenkin tietenkin muiden kulttilaisten
            kanssa!).
          </p>
        </NorppaWrapper>

        <NorppaWrapper>
          <ImageWrapper>
            <StaticImage
              src="../images/pullervo.png"
              alt="pullervo"
              layout="constrained"
            />
          </ImageWrapper>
          <h2>Pullervo - Nukkumisen, chillailun ja laiskottelun jumala</h2>

          <p>
            Norppa-isukki, Pällervön puoliso ja riitelykumppani. Pullervo oli
            toinen jumala, joka sai alkunsa Pällervön sarvesta. Pullervo on
            leppoisempaa sorttia, nauttii pitkistä köllöttelyistä
            lempikivellänsä ja täyteläisistä sardiini-aterioista. Avioliitto
            Pällervön kanssa on opettanut Pullervolle kärsivällisyyttä ja
            niinsanotun zen-henkisyyttä. Pullervo paheksuu itsensä
            ylirasittamista ja arvostaa collareita ja kalsareita.
          </p>
          <p>Aura: Rauhoittava, Heinä- ja Tammikuu</p>
          <p>
            Uhraus: Lahjoita lepopaikkasi Pullervolle yhdeksi yöksi ja nuku yö
            epämukavalla alustalla arvostaaksesi jatkossa hyvää lepokiveä
            enemmän. Tästä kuvatodiste kulttijohtajistolle!
          </p>
        </NorppaWrapper>

        <NorppaWrapper>
          <ImageWrapper>
            <StaticImage
              src="../images/nällervö.png"
              alt="Nällervö"
              layout="constrained"
            />
          </ImageWrapper>
          <h2>Nällervö - Ylensyönnin ja nälkäkiukun jumala</h2>

          <p>
            Nällervö on esikoinen ja Pullervon epävirallinen lempilapsi.
            Nällervö on tunnettu epäsäännöllisestä ruokarytmistänsä ja
            känkkäränkkäröimisestä. Nällervö rakastaa hyviä kestejä ja
            jälkiruokabuffetteja.
          </p>
          <p>Aura: Puutteelinen, Marras- ja Toukokuu</p>
          <p>
            Uhraus: Nällervön henkeen megamättö dinner-kestit kuutistien kesken!
          </p>
        </NorppaWrapper>

        <NorppaWrapper>
          <ImageWrapper>
            <StaticImage
              src="../images/nollervo_new.png"
              alt="Nollervo"
              layout="constrained"
            />
          </ImageWrapper>
          <h2>Nollervo - Nolouden jumala</h2>

          <p>
            Nollervoa kuvataan usein jumalista aliarvostetuimmaksi. Nollervon
            mahtia voi välillä olla vaikea tunnistaa hänen kömpelöiden
            pumpsahduksiensa ja vääriin kohtiin lausuttujen asiattomien
            vitsiensä takaa. Tämän kaiken alta paljastuu kuitenkin hurja
            maailmojennielijä ja sankarijumala. Legenda myös kertoo että
            Nollervo saattaa olla insinööri.
          </p>
          <p>Aura: Myötähäpeä, Elo- ja Helmikuu</p>
          <p>
            Uhraus: Luovuta nolo tarina Nollervon auraan jakamalla sen
            kuuttilaisten kesken. Myös jokainen kuukauden aikana kerrottu
            isävitsi ja setämiestsoukki parantaa uhraussaldoa.
          </p>
        </NorppaWrapper>

        <NorppaWrapper>
          <ImageWrapper>
            <StaticImage
              src="../images/gullervo.png"
              alt="Gullervo"
              layout="constrained"
            />
          </ImageWrapper>
          <h2>Gullervo - Juhlien, päihtyneisyyden ja seksin jumala</h2>
          <p>
            Gullervo on norppajumalien Casanova, juhlien marinadi ja aftereiden
            keisari. Gullervon löytää usein illanvietosta ja Gullervo osaa ottaa
            ilon irti elämän nautinnoista lapsekkaan huolettomalla otteella.{" "}
            <br />
            Eronnut? Kolmesti. <br /> Lapsia? Lukemattomasti (tilastot
            vaihtelevat lähteiden välillä). <br /> Päihdeongelma?
            Todennäköisesti.
          </p>{" "}
          <p> Aura: sekava, Syys- ja Maaliskuu </p>
          <p>
            Uhraus: Gehtoollinen Gullervon nimeen sekä seremoniaalinen
            illallinen kuutistien kesken. Kuukauden sisällä tapahtuva
            päihtyminen ja seksi on bonusta.
          </p>
        </NorppaWrapper>

        <NorppaWrapper>
          <ImageWrapper>
            <StaticImage
              src="../images/hallervo.png"
              alt="Hällervö"
              layout="constrained"
            />
          </ImageWrapper>
          <h2>
            Hällervö - Oikeaan aikaan paikalta poistumisen, katoilun ja
            ghostaamisen jumala
          </h2>

          <p>
            Hällervöstä emme tiedä paljoakaan, hän on onnistunut pysymään
            mysteerinä elämällä varjoissa. Hänet on aikoinaan adoptoitu ja tämä
            selittää hänen epä-norppamaiset ninjailutaidot. Hällervö on
            jumalperheen musta lammas, tai näin ainakin uskotaan, sillä häntä
            seuraa tietynlainen hämäräbisneksien aura. Hällervö myös osaa
            ghostaamisen jalon taidon ja hyödyntääkin tätä mielensä mukaan ja
            varsin usein.{" "}
          </p>
          <p> Aura: shady, Loka- ja Huhtikuu </p>
          <p>
            {" "}
            Uhraus: Vietä päivä ilman sähköä ja somea pysyäksesi off the grid ja
            arvostaaksesi piilevää pimeyttä sydämessäsi. (Poislukien tietenkin
            hätätilanteessa puhelimen käyttö sekä pakolliset laitteet kuten
            jääkaappi)
          </p>
        </NorppaWrapper>
      </DarkSection>
    </Layout>
  );
}
